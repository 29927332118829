<template>
  <div class="about">
    <s-header
      :hasBack="true"
      :back="'/teacher/case/list'"
      :title="'学案详情'"
    />
    <n-bar :userType="'teacher'" :activeItemName="'index'" />
    <support :otherClass="'width40'" />

    <div class="pageContent">
      <div class="modular-base">
        <div class="modular-item">
          <CaseInfo :isShowClass="true" :caseInfo="caseInfo"></CaseInfo>
        </div>

        <div class="modular-item">
          <div class="classList">
            <van-tabs v-model:active="activeClass" @change="changeClassTab">
              <van-tab v-for="item in classArr" :title="item.name"> </van-tab>
            </van-tabs>
          </div>
        </div>

        <div class="modular-item">
          <ul class="caseConList">
            <h4>学案习题</h4>
            <li @click="lookCaseInfo">
              <div class="caseInfo" :class="isCurCase ? 'current' : ''">
                <div class="iconBox">
                  <van-image
                    width="100%"
                    height="100%"
                    :src="
                      isCurCase
                        ? require('@/assets/images/curCase.png')
                        : require('@/assets/images/case.png')
                    "
                  />
                </div>
                <div class="infoBox">
                  <p>{{ case_statistics.title }}</p>
                  <ul>
                    <li>
                      <div class="title">
                        题目（{{
                          case_statistics.subjective +
                          case_statistics.objective
                        }}条）
                      </div>
                      <div>疑问数 {{ case_statistics.comprehension }}</div>
                    </li>
                    <li>
                      <div class="title">
                        主观题（{{ case_statistics.subjective }}条）
                      </div>
                      <div>订正数 {{ case_statistics.revision }}</div>
                    </li>
                    <li>
                      <div class="title">阅评率</div>
                      <div>{{ case_statistics.review_percent }}%</div>
                    </li>
                    <li>
                      <div class="title">完成进度</div>
                      <div>0%</div>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <h4 v-if="resList.length">教学资源</h4>
            <li
              class="res"
              v-for="(item, index) in resList"
              @click="changeShowContent(index, item.id)"
            >
              <div
                class="caseInfo"
                :class="activeCase == index ? 'current' : ''"
              >
                <div class="iconBox">
                  <van-image
                    width="100%"
                    height="100%"
                    :src="
                      activeCase == index
                        ? require('@/assets/images/curRels.png')
                        : require('@/assets/images/rels.png')
                    "
                  />
                </div>
                <div class="infoBox rels">
                  <p>{{ item.name }}</p>
                  <ul>
                    <li>
                      <div class="title">平均学习时长</div>
                      <div>
                        {{ secondsToIS(item.statistics.average_time).clock }}
                      </div>
                    </li>
                    <li>
                      <div class="title">学习人数</div>
                      <div>{{ item.statistics.learn }}人已学习</div>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="modular-content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onBeforeMount } from "vue";
import { useRoute, useRouter } from "vue-router";
import sHeader from "@/components/SimpleHeader";
import nBar from "@/components/NavBar";
import support from "@/components/Support";
import CaseInfo from "../components/CaseBasInfo.vue";
import { apiGetCaseInfo, apiGetCaseResource } from "@/service/teacher.js";
import { secondsToIS } from "@/common/js/utils.js";

const route = useRoute();
const router = useRouter();

let caseInfo = ref({});
let isCurCase = ref(true);
let classArr = ref([]);
let resList = ref([]);
let case_statistics = ref({});

const getCaseInfo = async (gradeId, classId) => {
  let res = await apiGetCaseInfo({
    case_id: route.query.case_id,
    gradeId,
    classId,
  });
  if (res) {
    caseInfo.value = res.data;
    classArr.value = res.data.gradeClassArr;
    await getCaseRes(route.query.grade, route.query.class);
  }
};
onBeforeMount(async () => {
  await getCaseInfo();
  await getActiveClass();
});

// 获取资源列表
const getCaseRes = async (gradeId, classId) => {
  let res = await apiGetCaseResource({
    gradeId,
    classId,
    caseId: route.query.case_id,
  });
  if (res) {
    resList.value = res.data.resource_list;
    case_statistics.value = res.data.case_statistics;
  }
};

const getActiveClass = () => {
  if (route.query.class) {
    classArr.value.forEach((item, index) => {
      if (
        item.grade_id == route.query.grade &&
        item.class_id == route.query.class
      ) {
        activeClass.value = index;
      }
    });
  }
};

let activeClass = ref(0);
let activeCase = ref(-1);

// 切换班级tab
const changeClassTab = async (e) => {
  getCaseRes(classArr.value[e].grade_id, classArr.value[e].class_id);
  getCaseInfo(classArr.value[e].grade_id, classArr.value[e].class_id);
  await router.push({
    path: "/teacher/CaseSis/studentListAndData",
    query: {
      case_id: route.query.case_id,
      class: classArr.value[e].class_id,
      grade: route.query.grade,
      resourceID: route.query.resourceID,
      // resId: resList.value[0].id,
    },
  });
  await getCaseInfo(route.query.grade, route.query.class);
};

// 点击学案习题
const lookCaseInfo = () => {
  isCurCase.value = true;
  activeCase.value = -1;
  router.push({
    path: "/teacher/CaseSis/studentListAndData",
    query: {
      case_id: route.query.case_id,
      class: classArr.value[activeClass.value].class_id,
      grade: classArr.value[activeClass.value].grade_id,
      resourceID: 0,
    },
  });
};

// 点击教学资源
const changeShowContent = async (index, id) => {
  isCurCase.value = false;
  activeCase.value = index;
  router.push({
    path: "/teacher/CaseSis/studentListAndData",
    query: {
      case_id: route.query.case_id,
      class: classArr.value[activeClass.value].class_id,
      grade: classArr.value[activeClass.value].grade_id,
      resourceID: id,
      resId: resList.value[index].id,
    },
  });
};
</script>

<style lang="less" scoped>
@import "@/common/style/caseList.less";

.caseConList {
  padding: 5px;

  background-color: rgb(215, 215, 215);
  border-radius: 10px;
  li {
    margin-bottom: 5px;
  }
  h4 {
    margin: 3px 0;
  }

  .current {
    color: rgb(102, 204, 255);
  }
  .caseInfo {
    height: 50px;
    padding: 5px;
    background-color: #fff;
    display: flex;
    // align-items: center;
    border-radius: 5px;
    cursor: pointer;
    .iconBox {
      width: 40px;
      height: 100%;
    }

    .infoBox {
      flex: 1;
      margin-left: 5px;
      p {
        margin: 0 0 5px 0;
        font-weight: 700;
      }

      ul {
        display: flex;
        justify-content: space-between;
        li {
          .title {
            margin-bottom: 5px;
          }
        }
      }
    }
    .rels {
      ul {
        justify-content: flex-start;
        li {
          margin-right: 20px;
        }
      }
    }
  }
}

.isList {
  color: rgb(102, 204, 255);
}
.dataAndStudentList {
  display: flex;
  padding: 5px;
  font-size: 12px;
  cursor: pointer;
  div {
    margin-right: 20px;
  }
}

.understand {
  margin-bottom: 10px;
}

.pie {
  margin-right: 5px;
}

.pd {
  padding: 0 10px;
  margin-bottom: 10px;
}

.resData {
  opacity: 0;
}

#leaning {
  padding: 5px;
  box-sizing: border-box;
  height: 200px;
  background-color: rgb(237, 237, 237);
  border-radius: 10px;
}

.studentInfo {
  display: flex;
  width: 100%;
  position: relative;
}

.classList {
  :deep(.van-tab__text) {
    font-size: 12px;
  }
}
</style>
